import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function MuiCard({
  heading,
  title,
  description,
  marginTop,
  marginLeft,
  color,
}) {
  return (
    <Card
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        position: "absolute",
        // marginTop,
        // marginLeft,
        // zIndex: "2",
        maxWidth: "300px",
        maxHeight: "370px",
        overflow: "auto",
        borderRadius: "20px",
        // textAlign: "center",
        padding: "15px",
      }}
      variant="outlined"
    >
      <CardContent>
        <Typography variant="h5" gutterBottom sx={{ color: "text.secondary" }}>
          {heading}
        </Typography>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        {/* <Typography sx={{ color: "text.secondary", mb: 1.5 }}>
        adjective
      </Typography> */}
        <Typography sx={{ marginTop: "10px", color }}>{description}</Typography>
      </CardContent>
      <CardActions>
        {/* <Button size="small">Learn More</Button> */}
      </CardActions>
    </Card>
  );
}

export default MuiCard;
