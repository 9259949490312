import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useForm } from "react-hook-form";
import logo from "../assets/192.png";
import axios from "axios";
const CssTextField = {
  "& .MuiOutlinedInput-root": {
    // color: "#000",
    borderRadius: "10px",
    fontFamily: "Arial",
    fontWeight: "bold",
    backgroundColor: "#282828",
    // Class for the border around the input field
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2e2e2e",
      borderWidth: "2px",
    },
  },
  // Class for the label of the input field
  "& .MuiInputLabel-outlined": {
    // color: "#2e2e2e",
    fontWeight: "bold",
  },
};

const ContactForm = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOnSubmit = async (data) => {
    setLoading(true);
    setFormData(data);
    try {
      await axios.post(
        "https://chwigrzvyk.execute-api.us-west-2.amazonaws.com/sendEmailStage/send-email", // API Gateway URL
        data
      );

      setLoading(false);
      reset({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
      setOpenModal(true);
    } catch (error) {
      setLoading(false);
      console.error("Error sending email:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  //   const validateForm = async (data = {}) => {
  //     const schmeaObj = {
  //       firstName: vine.string().minLength(3).alpha({
  //         allowSpaces: false,
  //         allowUnderscores: false,
  //         allowDashes: false,
  //       }),
  //       lastName: vine.string().minLength(3).alpha({
  //         allowSpaces: false,
  //         allowUnderscores: false,
  //         allowDashes: false,
  //       }),
  //       email: vine.string().email().minLength(5),
  //       phone: vine.string().mobile(),
  //       message: vine.string().minLength(100),
  //     };
  //     // const schema = vine.object(schmeaObj);
  //     // const validator = vine.compile(schema);
  //     // return await validator.validate(data);
  //   };

  return (
    <Box>
      {loading && (
        <div className="loading-overlay">
          <CircularProgress size={50} />
        </div>
      )}
      <Card
        sx={{
          maxWidth: 650,
          width: "100%",
          backgroundColor: "transparent",
          boxShadow: "0 0 65.3px 0 rgba(0, 0, 0, .16)",
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Typography
            sx={{
              color: "rgb(255,255,255,0.7)",
              fontWeight: "500",
              fontSize: "2rem",
              marginBottom: "25px",
            }}
            gutterBottom
            align="center"
          >
            Drop Us a Message
          </Typography>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid container spacing={2}>
              {/* First Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  //   value={formData.firstName}
                  onChange={handleChange}
                  required
                  //   sx={{
                  //     // borderRadius: "10px!important",
                  //     backgroundColor: "#282828",
                  //     border: "none",
                  //   }}
                  sx={CssTextField}
                  {...register("firstName", {
                    required: "First name is required",
                  })}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  disabled={loading}
                />
              </Grid>

              {/* Last Name */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  //   value={formData.lastName}
                  onChange={handleChange}
                  required
                  sx={CssTextField}
                  {...register("lastName", {
                    required: "Last name is required",
                  })}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  disabled={loading}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  //   value={formData.email}
                  onChange={handleChange}
                  required
                  sx={CssTextField}
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Please enter a valid email address",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  disabled={loading}
                />
              </Grid>

              {/* Phone */}
              <Grid item xs={12}>
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  name="phone"
                  //   value={formData.phone}
                  onChange={handleChange}
                  required
                  sx={CssTextField}
                  {...register("phone", {
                    required: "Phone number is required",
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: "Please enter a valid 10-digit phone number",
                    },
                  })}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  disabled={loading}
                />
              </Grid>

              {/* Question/Comment */}
              <Grid item xs={12}>
                <TextField
                  label="Question/Comment"
                  variant="outlined"
                  fullWidth
                  name="message"
                  //   value={formData.message}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  required
                  sx={CssTextField}
                  {...register("message", { required: "Message is required" })}
                  error={!!errors.message}
                  helperText={errors.message?.message}
                  disabled={loading}
                />
              </Grid>

              {/* Submit Button */}
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {/* <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                
                >
                  Submit
                </Button> */}
                <Button
                  type="submit"
                  //   variant="outlined"
                  startIcon={<img height={38} width={38} src={logo} />}
                  sx={{
                    padding: "10px 18px",
                    borderRadius: "25px",
                    color: "black",
                    fontSize: "1.1rem",
                    textTransform: "capitalize",
                    // border: "none",
                    fontWeight: "600",
                    backgroundColor: "white",
                    // width: "200px",
                    "&:hover": {
                      backgroundColor: "grey",
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Form Submitted Successfully</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Thank you, {formData.firstName} {formData.lastName}. We have
            received your message:
          </Typography>
          {/* <Typography variant="body2" sx={{ marginTop: 2 }}>
            "{formData.message}"
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ContactForm;
