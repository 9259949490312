import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Typography } from "@mui/material";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import MuiCard from "../components/MuiCard";
import "swiper/css/bundle";

// Make sure to import Swiper styles
// import "swiper/swiper-bundle.min.css";

const OurVision = () => {
  const images = [
    {
      src: "/five.jpg",
      title: "A place with pure innovation mindset",
    },
    {
      src: "/second.jpg",
      title: "We build modern tech with values",
    },
    {
      src: "/third.jpg",
      title: "Lets break and make things",
    },
  ];
  const cards = [
    {
      heading: "What we do ?",
      title: "Innovation",
      description: `We create products that make complex technologies simpler and easier
          to use. Our main focus is on how useful the product is, not how
          popular it becomes.`,
      marginLeft: "7%",
      marginTop: "10%",
      color: "rgb(138 172 165)",
    },
    {
      heading: "What makes us stand out ?",
      title: "Commitment to simplicity",
      description: `What makes us stand out is our commitment to simplicity and usefulness. 
      We focus on creating products that solve real problems, rather 
      than just following trends or chasing popularity. Our goal is to make technology 
      more accessible and practical for everyone.`,
      marginLeft: "40%",
      marginTop: "15%",
      color: "#c7bdaf",
    },
    {
      heading: "Director vision",
      title: "",
      description: `Day by day, humans are becoming more driven by greed due to the 
      rising obsession with money. In today's world, everything we do seems to be connected 
      to financial gain, and while this is inevitable to some extent, we must also focus on 
      slowing the degradation of our planet. We need to find ways to reconnect with our true 
      selves, moving away from the professional and materialistic pressures that dominate our 
      lives. Globalization is important, but losing 
      our roots in the process leads us to live lives that are more influenced by external forces
       than shaped by our true desires.`,
      marginLeft: "73%",
      marginTop: "8%",
      color: "#b08585",
    },
  ];

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      {/* {cards.map(
        ({ heading, title, description, marginTop, marginLeft, color }) => (
          <MuiCard
            heading={heading}
            title={title}
            description={description}
            marginTop={marginTop}
            marginLeft={marginLeft}
            color={color}
          />
        )
      )} */}

      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={3}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        breakpoints={{
          // Breakpoints for responsive design
          320: {
            slidesPerView: 1,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1, // 1 slide on screens smaller than 640px
          },
          768: {
            slidesPerView: 1, // 2 slides on screens larger than 768px
          },
          1024: {
            slidesPerView: 3, // 3 slides on screens larger than 1024px
          },
        }}
        loop={true} // Enable infinite loop
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <Box sx={{ position: "relative", height: "100vh" }}>
              <img
                src={image.src}
                alt={`carousel-${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(0, 0, 0, 0.5)", // Dim background
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: "20px",
                  margin: "3px",
                }}
              >
                <MuiCard
                  heading={cards[index].heading}
                  title={cards[index].title}
                  description={cards[index].description}
                  marginTop={cards[index].marginTop}
                  marginLeft={cards[index].marginLeft}
                  color={cards[index].color}
                />
                {/* <Typography
                  variant="h5"
                  sx={{
                    color: "#cbbcbc",
                    fontWeight: "bold",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  {image.title}
                </Typography> */}
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <Swiper
        modules={[Navigation, Pagination]} // Include the Swiper modules you need
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        loop
        autoplay={{ delay: 2 }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <Box sx={{ position: "relative", height: "100vh" }}>
              <img
                src={image.src}
                alt={`carousel-${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(0, 0, 0, 0.5)", // Dim background
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  paddingBottom: "20px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#cbbcbc",
                    fontWeight: "bold",
                    marginBottom: "20px",
                  }}
                >
                  {image.title}
                </Typography>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper> */}
    </Box>
  );
};

export default OurVision;
