import React from "react";
import { Box, Typography } from "@mui/material";

function Products() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url('/bigDark.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Typography
        variant="h2"
        sx={{
          color: "#e4e4e4;",
          p: 2,
          alignSelf: "end",
          textAlign: "center",
        }}
      >
        Coming Soon
      </Typography>
    </Box>
  );
}

export default Products;
