// import React from "react";
// import { Box, Typography, Container } from "@mui/material";

// function Footer() {
//   return (
//     <Box component="footer" sx={{ bgcolor: "background.paper", py: 3, mt: 8 }}>
//       <Container maxWidth="lg">
//         <Typography variant="body1"></Typography>
//         <Typography variant="body2" color="textSecondary" align="center">
//           © {new Date().getFullYear()} Spac7. All rights reserved.
//         </Typography>
//       </Container>
//     </Box>
//   );
// }

// export default Footer;

import React from "react";
import { Container, Grid, Typography, IconButton } from "@mui/material";
import { LinkedIn, Twitter, Instagram, Facebook } from "@mui/icons-material";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#171616",
        color: "white",
        padding: "20px 0",
        marginTop: "12px",
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          width={"60%"}
        >
          {/* Contact Column */}
          <Grid item xs={12} md={6} sx={{ maxWidth: "200px!important " }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontSize: "14px",
                color: "#90caf9",
                marginBottom: "6px",
                textTransform: "uppercase",
              }}
            >
              Contact
            </Typography>
            <ul className="footerContact">
              <li>
                <a href="mailto:info@spac7.com">info@spac7.com</a>
              </li>
              <li>
                <a href="mailto:career@spac7.com">career@spac7.com</a>
              </li>
              <li>
                <a href="mailto:director@spac7.com">director@spac7.com</a>
              </li>
            </ul>
          </Grid>

          {/* Social Connect Column */}
          <Grid item xs={12} md={6} sx={{ maxWidth: "130px!important" }}>
            <div>
              <IconButton
                color="inherit"
                href="https://www.linkedin.com/company/spac7"
                target="_blank"
                rel="noopener noreferrer"
                // sx={{ wido }}
              >
                <LinkedIn fontSize="large" />
              </IconButton>
              {/* <IconButton
                color="inherit"
                href="https://www.twitter.com/Spac7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://www.instagram.com/Spac7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </IconButton>
              <IconButton
                color="inherit"
                href="https://www.facebook.com/Spac7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook /> */}
              {/* </IconButton> */}
            </div>
          </Grid>
        </Grid>

        {/* Rights Reserved Text */}
        <Typography
          variant="body2"
          align="center"
          style={{ marginTop: "20px" }}
        >
          &copy; {new Date().getFullYear()} SPAC7 All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
