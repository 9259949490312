// src/components/Header.js
import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import logo from "../assets/t12.png";

const NavbarLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
  marginLeft: theme.spacing(3),
  "&.active": {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(0.5),
  },
}));

function Header() {
  const [navBg, setNavBg] = useState(false);

  const changeNavBg = () => {
    window.scrollY >= 100 ? setNavBg("#121212") : setNavBg(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: navBg || "transparent",
        transition: "all",
        paddingBottom: "10px",
        transitionDuration: "0.5s",
      }}
    >
      <Toolbar
        className="headerToolbar"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <img
          //   variant="h6"
          //   sx={{
          //     flexGrow: 1,
          //     color: "primary.main",
          //     fontWeight: "bold",
          //   }}

          src={logo}
          alt="Brand Logo"
          style={{ paddingTop: "20px" }}
        />

        <Box sx={{ paddingTop: "8px" }}>
          <NavbarLink to="/" end>
            Our Vision
          </NavbarLink>
          <NavbarLink to="/products">Products</NavbarLink>
          <NavbarLink to="/contact">Contact Us</NavbarLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
