import React from "react";

import ContactForm from "../components/ContactForm";
import { Typography } from "@mui/material";

function ContactUs() {
  return (
    <div className="mainContactUs">
      <div className="secondMain">
        <div className="headingContactUs"></div>

        <ContactForm />
      </div>
    </div>
  );
}

export default ContactUs;
